@import "src/scss/module";

.JobSearchForm {
  position: relative;
  :global {
    .js-form {
      padding: 0;
    }

    .js-form-input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      .js-input-wrapper {
        margin: 0 !important;
        width: 100%;
      }
    }

    .js-panel-border {
      border: none;
    }

    .js-input {
      background-color: #{$light};
      border-color: #{$light};
    }

    .js-button {
      background-color: #{$blue};
      border-color: #{$blue};
      white-space: nowrap;
      margin: 0;
    }
  }
}
