@import "src/scss/module";

.JobsFeed {
  position: relative;

  .bg_1 {
    top: 0;
    right: 0;
  }

  .bg_2 {
    left: 0;
    top: 40%;
  }

  * {
    font-family: $font-family-sans-serif;
  }

  .results {
    :global {
      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -6rem;
      }
    }
  }

  :global {
    .js-panel-border {
      border-radius: 0;
    }

    .js-form {
      display: none;
    }

    .js-columns-container {
      flex-direction: column;
      margin: 0;
    }

    .js-filters-column {
      width: 100%;
      display: flex;
      gap: 0.25rem;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      :global {
        .form-check-input[type="checkbox"] {
          border-radius: 1rem;

          &:checked {
            background-color: #{$green};
            border-color: #{$green};
          }
        }
      }

      .js-filters-toggle-button {
        display: inline-block !important;
        background: #{$gradient-cyan};
        color: #{$white};
        border: 0;
        font-weight: bold;
        border-radius: 0;
        white-space: nowrap;
      }

      .js-filters-closed {
        height: auto !important;
      }

      .js-filters-collapse {
        display: flex;
        width: 100%;
        gap: 0.25rem;
        overflow: initial;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      .js-filter-box {
        padding: 0;
        gap: 0;
        background-color: #{$light};
        position: relative;
        flex-grow: 1;
        border: none;

        +.js-filter-box {
          margin-top: 0;
        }

        .js-filter-category-open {
          position: absolute;
          top: 100%;
          background-color: #{$light};
          width: 100%;
          z-index: #{$zindex-popover};
        }

        .js-filter-title {
          margin-bottom: 0;

          &.js-filter-title-closed {
            opacity: 1;

            & .js-filter-title-button {
              &:after {
                background-image: #{escape-svg(
 url('data:image/svg+xml,<svg viewBox="0 0 14 14" fill="#141821" xmlns="http://www.w3.org/2000/svg"><path d="M1.75 7.00002C1.75 6.67785 2.01117 6.41669 2.33333 6.41669H11.6667C11.9888 6.41669 12.25 6.67785 12.25 7.00002C12.25 7.32219 11.9888 7.58335 11.6667 7.58335H2.33333C2.01117 7.58335 1.75 7.32219 1.75 7.00002Z"/></svg>'))
              }

              ;
            }
          }
        }

        &.js-filter-title-open {
          & .js-filter-title-button {
            &:after {
              background-image: #{escape-svg(
 url('data:image/svg+xml,<svg viewBox="0 0 12 12" fill="#262B43" xmlns="http://www.w3.org/2000/svg"><path d="M6.58317 0.75002C6.58317 0.427854 6.322 0.166687 5.99984 0.166687C5.67767 0.166687 5.4165 0.427854 5.4165 0.75002V5.41669H0.749837C0.427671 5.41669 0.166504 5.67785 0.166504 6.00002C0.166504 6.32219 0.427671 6.58335 0.749837 6.58335H5.4165V11.25C5.4165 11.5722 5.67767 11.8334 5.99984 11.8334C6.322 11.8334 6.58317 11.5722 6.58317 11.25V6.58335H11.2498C11.572 6.58335 11.8332 6.32219 11.8332 6.00002C11.8332 5.67785 11.572 5.41669 11.2498 5.41669H6.58317V0.75002Z"/></svg>'))
            }

            ;
          }
        }
      }

      &>div {
        display: flex;

        &>.js-filter-title-button {
          padding: 0.5rem 1rem;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:after {
            display: inline-flex;
            width: 14px;
            height: 14px;
            content: "";
            align-items: center;
            font-size: 1rem;
          }
        }
      }

      .js-filter-title-button {
        font-weight: #{$font-weight-bold};
        background-color:#{$blue} !important;
        color: white !important;
      }

      .js-filter-title-icon {}
    }

    .js-filter-values-section {
      margin-bottom: 0;
      padding: 1rem;
      max-height: 300px;
      overflow: auto;
    }

    .js-filter-value {
      width: 100%;
    }

    .js-filter-value-content {
      gap: 0.5rem;

      &.js-filter-selected-class {
        font-weight: 500;
      }

      .js-filter-selected,
      .js-filter-unselected {
        opacity: 0;
        width: 0;
        margin: 0;
      }

      .js-filter-value-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .js-filter-count {
        margin-left: auto;
        background-color: transparent;
        color: #{$navy};
      }
    }
  }
}

.js-results {
  flex: 1;

  @include media-breakpoint-up(md) {
    margin-left: 1.5rem;
  }
}

.js-pagination {
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;

  &>div {
    display: flex;
  }

  button {
    background-color: #{$cyan};
    border-color: #{$cyan};
    color: #{$navy};
    margin: auto;
    font-weight: 500;
    padding: 0.5rem 2rem;
  }
}
}
}