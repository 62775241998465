@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

$font-family-sans-serif:
  "Montserrat",
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

$blue: #2e4386;
$blue-text-emphasis: shade-color($blue, 60%);
$blue-bg-subtle: tint-color($blue, 80%);
$blue-border-subtle: tint-color($blue, 60%);

$cyan: #00d5fc;
$cyan-text-emphasis: shade-color($cyan, 60%);
$cyan-bg-subtle: tint-color($cyan, 80%);
$cyan-border-subtle: tint-color($cyan, 60%);

$green: #6adc84;
$green-text-emphasis: shade-color($green, 60%);
$green-bg-subtle: tint-color($green, 80%);
$green-border-subtle: tint-color($green, 60%);

$navy: #1f305a;
$navy-text-emphasis: shade-color($navy, 60%);
$navy-bg-subtle: tint-color($navy, 80%);
$navy-border-subtle: tint-color($navy, 60%);

$light: #f3f3f4;

$theme-colors: (
  "blue": $blue,
  "cyan": $cyan,
  "green": $green,
  "navy": $navy,
  "light": $light,
  "dark": $dark,
);

$gradient-cyan: linear-gradient(180deg, #{$cyan} 0%, #0089f9 100%);
$gradient-cyan-270: linear-gradient(270deg, #{$cyan} 0%, #0089f9 100%);
$gradient-green: linear-gradient(180deg, #{$green} 0%, #3ac96b 100%);
$gradient-green-270: linear-gradient(270deg, #{$green} 0%, #3ac96b 100%);

$hexagon-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
$hexagon-clip-path-alt: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);

$font-size-xs: 0.6875rem;
$font-size-sm: 0.8125rem;
$font-size-lg: 1.1875rem;

$h1-font-size: 3rem;
$h3-font-size: 1.8rem;
$headings-font-weight: 600;

$offcanvas-horizontal-width: 595px;

$btn-hover-bg-tint-amount: 30%;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
  8: $spacer * 18,
);

$grid-breakpoints: (
  xs: 0,
  sm: 620px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 660px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px,
);
